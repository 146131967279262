<template>
  <div>
    <h4>Dystopia-Utopia</h4>
    <div class="small mb-3">Installation with AI</div>
    <p class="small">
      近年盛り上がりを見せるAIの手法の一つであるGAN（Generative adversarial networks）を使って、ディストピアな画像を学習させたAIと、ユートピアな画像を学習させたAIそれぞれに画像を自動生成させた。<br />
      AIは”いい”ものを学習すると”いい”ものを生み出し、”悪い”ものを学習すると”悪い”ものを生み出します。それは、見たものや聞いたものによって大きく変わる人間も同じです。<br />
      AIや私たちにとって、“いい”ものや”悪い”ものとは何なのでしょうか？<br/>
      AIや私たちは、何を学習し、将来何を生み出すのでしょうか？<br/>
      <br/>
      制作協力: Naoki Watanabe（エンジニアリング）
    </p>
    <img src="@/assets/dystopia1.jpg" class="img-fluid" />
    <div class="text-center mb-4">"Dystopia"</div>
    <img src="@/assets/utopia1.jpg" class="img-fluid" />
    <div class="text-center mb-4">"Utopia"</div>
    <div class="video mb-5">
      <iframe width="100%" src="https://www.youtube-nocookie.com/embed/pjxexC9SDOc" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen rel=0></iframe>
    </div>
    <div class="video mb-5">
      <iframe width="560" height="315" src="https://www.youtube-nocookie.com/embed/Gd-iCJPjxFk" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen rel=0></iframe>
    </div>
    <img src="@/assets/dystopia2.jpg" class="img-fluid text-center" />
    <img src="@/assets/utopia2.jpg" class="img-fluid text-center" />
  </div>
</template>

<style>
img {
  margin-bottom: 10px;
}
</style>
